/* src/RadioTicker.module.css */

.tickerContainer {
  color: #ffffff; /* White text */
  padding: 20px;
  text-align: center;
  font-family: titling-gothic-fb-wide, sans-serif;
  overflow: hidden;
}

.tickerContainer > h2 {
  margin-bottom: 16px;
}

.trackItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px; /* Adjust padding for better spacing */
  margin: 5px; /* Space between items */
  border: 1px solid rgba(255, 255, 255, 0.1); /* Optional border for each item */
  background-color: #0e0f0f;
  transition: background 0.3s;
  border-radius: 5px; /* Rounded corners */
  border-width: 0;
  border: 5px #332d24 solid;
  padding: 20px;
  border-radius: 0px !important;
  margin: 10px;
  color: #fff;
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 400;
  flex-wrap: wrap;
  row-gap: 8px;
}

.trackItem > span,
.trackItem > div {
  margin-right: 8px;
}

#station-and-song {
  display: flex !important;
  justify-content: start !important;
}

.trackItem:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Slight hover effect */
}

.playButton {
  background-color: #0e0f0f;
  color: white;
  border: 5px #332d24 solid;
  padding: 5px 10px;
  border-radius: 0px !important;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s;
}

.playButton:hover {
  background-color: #f74209;
}

.loading {
  font-size: 20px;
  font-weight: bold;
}

.stationDescription {
  word-wrap: break-word;
  font-weight: 600;
  text-shadow: 5px 5px #1f1e1e;
  margin: auto 0;
}
