@font-face {
  font-family: "Exodus";
  src: url("../Exodus.woff") format("woff");
}
body {
  background-image: url("https://storage.googleapis.com/soonspins_site_images/classy-fabric.png");
  font-family: titling-gothic-fb-wide, sans-serif;
  background-color: #0f0f0f;
}

#main-carousel {
  width: 100%;
  height: 100vh;
  position: relative;
  padding: 0;
}
#main-carousel .carousel-caption {
  color: white !important;
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 700;
}
#main-carousel h3 {
  text-transform: uppercase;
  font-weight: 600;
  text-shadow: 5px 5px #f74209;
}

.d-block .w-100 {
  height: 100%;
}

img {
  /*height: -webkit-fill-available;*/
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  height: 50px !important;
  width: 50px !important;
  box-shadow: 7.5px 7.55px #1f1e1e;
}

#loading-div {
  color: white !important;
}

.carousel-inner {
  height: 100vh;
  text-align: center !important;
}
.carousel-inner img {
  margin-top: 25px !important;
  overflow: hidden;
  height: 80% !important;
  width: 75% !important;
  filter: brightness(68%);
  box-shadow: 25px 25px 0px #454343;
}

.nav-link {
  color: white !important;
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: large;
  text-decoration: none;
}

.navbar {
  /* background-color: #b8821c; */
  background-size: cover;
  text-shadow: 5px 5px #f74209;
}

.navbar-brand {
  color: white !important;
  text-decoration: none;
}
.navbar-brand img {
  /*border-style: solid;
      border-width: 5px;
      border-color: black;*/
}

.carousel-height {
  height: 100% !important;
}

.imageContainer {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: relative;
}

.imageCenterer {
  width: 1000px;
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: -500px;
}
.imageCenterer img {
  display: block;
  margin: 0 auto;
}

.title-h1 {
  color: white;
  text-shadow: 5px 5px #f74209;
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  font-size: 2rem;
}

.artist-row {
  margin-top: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-left: auto;
}
.artist-row .card-pic {
  height: 300px;
  width: 100%;
}
.artist-row .card {
  width: fit-content;
  border-width: 0px;
  background-color: #f74209 !important;
  background-image: url("https://www.transparenttextures.com/patterns/concrete-wall.png");
  border-radius: 0px;
  border-color: white;
  padding: 20px;
  color: white;
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 400;
}

.line {
  width: 100%;
  border-bottom: 5px solid #332d24 !important;
}

.video-container .container {
  width: 100% !important;
  height: 100vh !important;
}

.footer {
  background-color: #4b1e10;
  color: white !important;
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 700;
  font-style: normal;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 24px;
}

.nav-link,
a {
  text-decoration: none !important;
  color: white !important;
}

#about-text {
  color: #bd3712;
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 400;
  font-style: normal;
}

#adminPage,
#admin-login {
  color: #bd3712;
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 400;
  font-style: normal;
}

form .row {
  margin-bottom: 10px;
}

.card-title {
  color: white;
  text-shadow: 5px 5px #f74209;
  font-weight: 600;
}

.card-button {
  border-radius: 0px;
  border: 5px #332d24 solid;
}

.page-number-button {
  cursor: pointer;
  margin-right: 4px;
  background: transparent;
  border: solid #332d24 4px;
}
.page-number-button.forward, .page-number-button.backward {
  color: #fff;
}

.Dropdown-root {
  border: 5px #332d24 solid;
  color: #fff !important;
}
.Dropdown-root .Dropdown-menu {
  border: 5px #332d24 solid;
  width: 350px;
  left: -5px;
}
.Dropdown-root .Dropdown-control,
.Dropdown-root .Dropdown-option {
  background-color: #0e0f0f !important;
  border: none !important;
  color: #fff !important;
}
.Dropdown-root .Dropdown-arrow {
  display: none;
}

.form-inline,
.form-control {
  width: auto;
  font-size: 0.5rem;
}

.form-inline,
.form-group {
  display: inline-block;
}

.card-img-top {
  border-radius: 3.5px;
}

.soonspins-card {
  border-width: 0;
  background-color: #0e0f0f !important;
  background-image: url(https://www.transparenttextures.com/patterns/concrete-wall.png);
  border: 5px #332d24 solid;
  padding: 20px;
  border-radius: 0px !important;
  margin: 10px;
  color: #fff;
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 400;
}

.card-body > * {
  margin-bottom: 12px;
}

.carousel-slide {
  position: relative;
}

.carousel-indicators {
  position: absolute;
  bottom: 64px;
}

#featured-artists {
  background: none;
}

.navbar-toggler {
  border: solid #332d24 4px !important;
  border-radius: 0px;
}

.navbar-toggler-icon {
  color: white;
}

input[type=range].my-slider {
  width: 100%;
  margin: 4.55px 0;
  background-color: transparent;
  -webkit-appearance: none;
}

input[type=range].my-slider:focus {
  outline: none;
}

input[type=range].my-slider::-webkit-slider-runnable-track {
  background: rgba(48, 138, 213, 0.1);
  border: 1.7px solid #010101;
  border-radius: 23.1px;
  width: 100%;
  height: 4.9px;
  cursor: pointer;
}

input[type=range].my-slider::-webkit-slider-thumb {
  margin-top: -6.25px;
  width: 14px;
  height: 14px;
  background: #f74209;
  border: 2.5px solid #00001e;
  border-radius: 5px;
  cursor: pointer;
  -webkit-appearance: none;
}

input[type=range].my-slider:focus::-webkit-slider-runnable-track {
  background: #4596d9;
}

input[type=range].my-slider::-moz-range-track {
  background: rgba(48, 138, 213, 0.1);
  border: 1.7px solid #010101;
  border-radius: 23.1px;
  width: 100%;
  height: 4.9px;
  cursor: pointer;
}

input[type=range].my-slider::-moz-range-thumb {
  width: 14px;
  height: 14px;
  background: #f74209;
  border: 2.5px solid #00001e;
  border-radius: 5px;
  cursor: pointer;
}

input[type=range].my-slider::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 5.45px 0;
  color: transparent;
  width: 100%;
  height: 4.9px;
  cursor: pointer;
}

input[type=range].my-slider::-ms-fill-lower {
  background: #287dc4;
  border: 1.7px solid #010101;
  border-radius: 46.2px;
}

input[type=range].my-slider::-ms-fill-upper {
  background: rgba(48, 138, 213, 0.1);
  border: 1.7px solid #010101;
  border-radius: 46.2px;
}

input[type=range].my-slider::-ms-thumb {
  width: 14px;
  height: 14px;
  background: #f74209;
  border: 2.5px solid #00001e;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}

input[type=range].my-slider:focus::-ms-fill-lower {
  background: rgba(48, 138, 213, 0.1);
}

input[type=range].my-slider:focus::-ms-fill-upper {
  background: #4596d9;
}

/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
  how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type=range].my-slider {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}
#right_curtain .carousel-inner img {
  object-fit: cover;
}

/*#main-carousel {
    #right_curtain {
    z-index: 1;
    position: fixed;
    width: auto;
    height: 100%;
  }
  }*/
iframe {
  display: block;
  width: 100%;
}

.audio-player {
  padding: 15px;
}
.audio-player #forward-icon {
  display: none;
  content: url("https://storage.cloud.google.com/soonspins_site_images/fast-forward.svg?authuser=2") !important;
}
.audio-player #rewind-icon {
  display: none;
  content: url("https://storage.cloud.google.com/soonspins_site_images/rewind.svg?authuser=2") !important;
}
.audio-player #volume-icon {
  content: url("volume.svg") !important;
  margin-right: 5px;
}
.audio-player #loop-icon {
  display: none;
}
.audio-player #volume {
  margin-right: 5px;
  cursor: pointer;
}
.audio-player #play {
  cursor: pointer;
}
.audio-player .marquee {
  font-family: titling-gothic-fb-wide, sans-serif;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
.audio-player .audio-player-time {
  font-family: titling-gothic-fb-wide, sans-serif;
  font-size: 15px;
}