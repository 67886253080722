@font-face {
  font-family: Exodus;
  src: url(../Exodus.woff) format("woff");
}
body {
  background-image: url(https://storage.googleapis.com/soonspins_site_images/classy-fabric.png);
  font-family: titling-gothic-fb-wide, sans-serif;
  background-color: #0f0f0f;
}

#main-carousel {
  width: 100%;
  height: 100vh;
  position: relative;
  padding: 0;
}
#main-carousel .carousel-caption {
  color: #fff !important;
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 700;
}
#main-carousel h3 {
  text-transform: uppercase;
}
.d-block .w-100 {
  height: 100%;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  height: 50px !important;
  width: 50px !important;
  box-shadow: 7.5px 7.55px #1f1e1e;
}
#loading-div {
  color: #fff !important;
}
.carousel-inner {
  height: 100vh;
  border-radius: 25px !important;
  text-align: center !important;
}
.carousel-inner img {
  margin-top: 25px !important;
  overflow: hidden;
  height: 80% !important;
  width: 75% !important;
  filter: brightness(68%);
  box-shadow: 25px 25px 0 #454343;
}
.nav-link {
  color: #fff !important;
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: large;
  text-decoration: none;
}
.navbar {
  /* background-color: #b8821c; */
  background-size: cover;
  text-shadow: 5px 5px #f74209;
}
.navbar-brand {
  color: #fff !important;
  text-decoration: none;
}
.carousel-height {
  height: 100% !important;
}
.imageContainer {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: relative;
}
.imageCenterer {
  width: 1000px;
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: -500px;
}
.imageCenterer img {
  display: block;
  margin: 0 auto;
}
.title-h1 {
  color: #fff;
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  font-size: 2rem;
}
.artist-row {
  margin-top: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-left: auto;
}
.artist-row .card-pic {
  height: 300px;
  width: 100%;
}
.artist-row .card {
  width: fit-content;
  border-width: 0;
  background-color: #f74209 !important;
  background-image: url(https://www.transparenttextures.com/patterns/concrete-wall.png);
  border-radius: 0;
  border-color: #fff;
  padding: 20px;
  color: #fff;
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 400;
}
.line {
  width: 100%;
  border-bottom: 5px solid #332d24 !important;
}
.video-container .container {
  width: 100% !important;
  height: 100vh !important;
}
.footer {
  background-color: #4b1e10;
  color: #fff !important;
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 700;
  font-style: normal;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 24px;
}
.nav-link,
a {
  text-decoration: none !important;
  color: #fff !important;
}
#about-text {
  color: #bd3712;
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 400;
  font-style: normal;
}
#admin-login,
#adminPage {
  color: #bd3712;
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 400;
  font-style: normal;
}
form .row {
  margin-bottom: 10px;
}
#twitch-video {
  border-width: 10px;
  border-color: #000;
}
.form-control,
.form-inline {
  width: auto;
  font-size: 0.5rem;
}
.form-group,
.form-inline {
  display: inline-block;
}
.card-img-top {
  border: 5px #332d24 solid;
  border-radius: 0px !important;
}
.soonspins-card {
  border-width: 0;
  background-color: #0e0f0f !important;
  background-image: url(https://www.transparenttextures.com/patterns/concrete-wall.png);
  border: 5px #332d24 solid;
  padding: 20px;
  border-radius: 0px !important;
  margin: 10px;
  color: #fff;
  font-family: titling-gothic-fb-wide, sans-serif;
  font-weight: 400;
}
.card-body > * {
  margin-bottom: 12px;
}
.carousel-slide {
  position: relative;
}
.carousel-indicators {
  position: absolute;
  bottom: 64px;
}
#featured-artists {
  background: 0 0;
}
.navbar-toggler-icon {
  color: #fff;
}
input[type="range"].my-slider {
  width: 100%;
  margin: 4.55px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
input[type="range"].my-slider:focus {
  outline: 0;
}
input[type="range"].my-slider::-webkit-slider-runnable-track {
  background: rgba(48, 138, 213, 0.1);
  border: 1.7px solid #010101;
  border-radius: 23.1px;
  width: 100%;
  height: 4.9px;
  cursor: pointer;
}
input[type="range"].my-slider::-webkit-slider-thumb {
  margin-top: -6.25px;
  width: 14px;
  height: 14px;
  background: #f74209;
  border: 2.5px solid #00001e;
  border-radius: 5px;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type="range"].my-slider:focus::-webkit-slider-runnable-track {
  background: #4596d9;
}
input[type="range"].my-slider::-moz-range-track {
  background: rgba(48, 138, 213, 0.1);
  border: 1.7px solid #010101;
  border-radius: 23.1px;
  width: 100%;
  height: 4.9px;
  cursor: pointer;
}
input[type="range"].my-slider::-moz-range-thumb {
  width: 14px;
  height: 14px;
  background: #f74209;
  border: 2.5px solid #00001e;
  border-radius: 5px;
  cursor: pointer;
}
input[type="range"].my-slider::-ms-track {
  background: 0 0;
  border-color: transparent;
  border-width: 5.45px 0;
  color: transparent;
  width: 100%;
  height: 4.9px;
  cursor: pointer;
}
input[type="range"].my-slider::-ms-fill-lower {
  background: #287dc4;
  border: 1.7px solid #010101;
  border-radius: 46.2px;
}
input[type="range"].my-slider::-ms-fill-upper {
  background: rgba(48, 138, 213, 0.1);
  border: 1.7px solid #010101;
  border-radius: 46.2px;
}
input[type="range"].my-slider::-ms-thumb {
  width: 14px;
  height: 14px;
  background: #f74209;
  border: 2.5px solid #00001e;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 0;
}
input[type="range"].my-slider:focus::-ms-fill-lower {
  background: rgba(48, 138, 213, 0.1);
}
input[type="range"].my-slider:focus::-ms-fill-upper {
  background: #4596d9;
}
@supports (-ms-ime-align: auto) {
  input[type="range"].my-slider {
    margin: 0;
  }
}
#right_curtain .carousel-inner img {
  object-fit: cover;
}
iframe {
  display: block;
  width: 100%;
}
.audio-player {
  padding: 15px;
}
.audio-player #forward-icon {
  display: none;
  content: url(https://storage.cloud.google.com/soonspins_site_images/fast-forward.svg?authuser=2) !important;
}
.audio-player #rewind-icon {
  display: none;
  content: url(https://storage.cloud.google.com/soonspins_site_images/rewind.svg?authuser=2) !important;
}
.audio-player #volume-icon {
  content: url(volume.svg) !important;
  margin-right: 5px;
}
.audio-player #loop-icon {
  display: none;
}
.audio-player #volume {
  margin-right: 5px;
  cursor: pointer;
}
.audio-player #play {
  cursor: pointer;
}
.audio-player .marquee {
  font-family: titling-gothic-fb-wide, sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}
.audio-player .audio-player-time {
  font-family: titling-gothic-fb-wide, sans-serif;
  font-size: 15px;
}
